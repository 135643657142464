.logo{
  transition: margin-left 0.4s ease-in-out;
  height: 3rem;
}

.logo.open {
  margin-left: 36.87vh; 
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../public/fonts/source-sans-pro/SourceSansPro-Regular.otf");
}

@font-face {
  font-family: 'Caveat';
  src: url("../public/fonts/caveat/static/Caveat-Regular.ttf");
}

div,h1, h2, h3, h4, h5, p, span, .text {
  font-family: 'Source Sans Pro', sans-serif;
}

.swal2-top-end {
  z-index: 9999;
  position: "fixed";
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 5.6em;
  margin-right: 1.7em;
}

.swal2-container {
  z-index: 9999;
  position: "fixed";
  top: 0;
  right: 0;
  bottom: 0;
}

.primaryLight {
  background-color: #FAD2E0;
}

#infoHtml > h1,#infoHtml > h2,#infoHtml > h3{
    font-size: 30;
    color: #BE217D;
    text-align: left;
    font-family: "Caveat", cursive;
}

#infoHtml > p{
    font-size: 14;
    text-align: justify;
}

.css-plesty-MuiDataGrid-root .MuiDataGrid-columnHeader, .css-plesty-MuiDataGrid-root .MuiDataGrid-cell{
  outline: none!important;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    background-color: rgba(254, 207, 231, .55);
  }

  .container-spinner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
	  background-color: rgba(254, 207, 231, .6);
    }